import { DSFlex, DSSpacer, DSText } from '@zoe/ds-web'
import { DSIcon } from '@zoe/ds-web'
import Link from 'next/link'
import { trackFooterClick } from './Footer'
import styled from 'styled-components'

const StyledMenuContainer = styled.div`
  a {
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: translateY(-2px);
    }
  }
`

export const FooterSocialMenu: React.FC = () => (
  <StyledMenuContainer aria-label="Social media links">
    <DSText as="h3" variant="fluid-paragraph-200" color="primary-inverse" weight="light">
      Follow us
    </DSText>
    <DSSpacer size={12} direction="vertical" />
    <DSFlex direction="row" justifyContent="flex-start" gap={12}>
      <Link
        href="https://www.instagram.com/zoe/"
        target="_blank"
        rel="noreferrer"
        onClick={() => trackFooterClick('instagram')}
        title="Instagram"
        aria-label="Follow us on Instagram"
      >
        <DSIcon name="instagram" color="inverse" size={24} ariaHidden />
      </Link>
      <Link
        href="https://www.facebook.com/joinzoe1/"
        target="_blank"
        rel="noreferrer"
        onClick={() => trackFooterClick('facebook')}
        title="Facebook"
        aria-label="Follow us on Facebook"
      >
        <DSIcon name="facebook" color="inverse" size={24} ariaHidden />
      </Link>
      <Link
        href="https://www.youtube.com/c/ZOE-health"
        target="_blank"
        rel="noreferrer"
        onClick={() => trackFooterClick('youtube')}
        title="YouTube"
        aria-label="Subscribe on YouTube"
      >
        <DSIcon name="youtube" color="inverse" size={24} ariaHidden />
      </Link>
    </DSFlex>
  </StyledMenuContainer>
)
