import { DSBadge, DSFlex, DSText } from '@zoe/ds-web'
import React from 'react'
import styled from 'styled-components'
import { DSSpacer } from '@/components/ds/Spacer/Spacer'
import { trackCardClickEvent } from '@/lib/gtm/gtmV2'
import Image from 'next/image'

const StyledFooterCardsContainer = styled.div`
  overflow-x: auto;
  display: flex;
  gap: var(--grid-24);
  padding: var(--grid-4) 0;

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const StyledCard = styled.div<{ $backgroundColor?: string }>`
  width: 250px;
  min-width: 250px;
  display: block;
  border-radius: var(--radius-16);
  background-color: var(--color-surface-neutral);
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: translateY(-4px);
  }
  img {
    border-radius: var(--radius-16);
  }
`

const StyledDSBadgeWrapper = styled.div`
  position: absolute;
  left: 16px;
  top: 16px;
`

const handleCardClick = (label: string) => {
  trackCardClickEvent({ section: 'footer', textOrImageUrl: label, linkPosition: 'footer' })
  window.location.href = `/${label}`
}

export const FooterCards: React.FC = () => {
  return (
    <StyledFooterCardsContainer>
      <StyledCard onClick={() => handleCardClick('podcast')}>
        <StyledDSBadgeWrapper>
          <DSBadge surfaceColor="navy-bold" textColor="primary-inverse">
            Podcast
          </DSBadge>
        </StyledDSBadgeWrapper>
        <Image src="/assets/img/footer/podcast.jpg" width={250} height={250} alt="Podcast cover" />
        <DSSpacer size={16} direction="vertical" />
        <DSFlex direction="row">
          <DSSpacer size={16} direction="horizontal" />
          <DSText as="p" variant="fluid-paragraph-200" weight="regular" color="primary">
            Listen to the #1 health podcast in the UK
          </DSText>
          <DSSpacer size={16} direction="horizontal" />
        </DSFlex>
        <DSSpacer size={16} direction="vertical" />
      </StyledCard>
      <StyledCard onClick={() => handleCardClick('daily30')}>
        <StyledDSBadgeWrapper>
          <DSBadge surfaceColor="navy-bold" textColor="primary-inverse">
            Daily30+
          </DSBadge>
        </StyledDSBadgeWrapper>
        <Image src="/assets/img/footer/daily30.jpg" width={250} height={250} alt="Daily30+ cover" />
        <DSSpacer size={16} direction="vertical" />
        <DSFlex direction="row">
          <DSSpacer size={16} direction="horizontal" />
          <DSText as="p" variant="fluid-paragraph-200" weight="regular" color="primary">
            Add a scoop of ZOE science to your plate
          </DSText>
          <DSSpacer size={16} direction="horizontal" />
        </DSFlex>
        <DSSpacer size={16} direction="vertical" />
      </StyledCard>
      <StyledCard onClick={() => handleCardClick('menoscale')}>
        <StyledDSBadgeWrapper>
          <DSBadge surfaceColor="navy-bold" textColor="primary-inverse">
            MenoScale
          </DSBadge>
        </StyledDSBadgeWrapper>
        <Image src="/assets/img/footer/menoscale.jpg" width={250} height={250} alt="MenoScale cover" />
        <DSSpacer size={16} direction="vertical" />
        <DSFlex direction="row">
          <DSSpacer size={16} direction="horizontal" />
          <DSText as="p" variant="fluid-paragraph-200" weight="regular" color="primary">
            Make sense of your menopause symptoms. Get your score.
          </DSText>
          <DSSpacer size={16} direction="horizontal" />
        </DSFlex>
        <DSSpacer size={16} direction="vertical" />
      </StyledCard>
    </StyledFooterCardsContainer>
  )
}
