import { DSSpacer, DSText } from '@zoe/ds-web'
import { DSLinkText } from '@/components/ds/LinkText/LinkText'
import { trackFooterClick } from './Footer'
import styled from 'styled-components'

const StyledMenuContainer = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: translateY(-2px);
    }
  }
`

export const FooterResourcesMenu: React.FC = () => (
  <StyledMenuContainer>
    <DSText as="h5" variant="fluid-label-100" weight="semiBold" color="primary-inverse">
      Resources
    </DSText>
    <DSSpacer size={16} direction="vertical" />
    <ul>
      <li>
        <DSLinkText href="/faqs" onPress={() => trackFooterClick('Help')}>
          <DSText as="span" weight="light" variant="fluid-label-100" color="primary-inverse">
            Help
          </DSText>
        </DSLinkText>
        <DSSpacer size={12} direction="vertical" />
      </li>

      <li>
        <DSLinkText href="/learn" onPress={() => trackFooterClick('Blog')}>
          <DSText as="span" weight="light" variant="fluid-label-100" color="primary-inverse">
            Blog
          </DSText>
        </DSLinkText>
        <DSSpacer size={12} direction="vertical" />
      </li>

      <li>
        <DSLinkText href="/research" onPress={() => trackFooterClick('Research updates')}>
          <DSText as="span" weight="light" variant="fluid-label-100" color="primary-inverse">
            Research updates
          </DSText>
        </DSLinkText>
        <DSSpacer size={12} direction="vertical" />
      </li>

      <li>
        <DSLinkText href={`${process.env.HEALTH_STUDY_URL}`} onPress={() => trackFooterClick('ZOE Health Study')}>
          <DSText as="span" weight="light" variant="fluid-label-100" color="primary-inverse">
            ZOE Health Study
          </DSText>
        </DSLinkText>
      </li>
    </ul>
  </StyledMenuContainer>
)
